import React, { useState, useEffect } from "react"
import { ListItem, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => {
  const ret = {}
  for (var i = 1; i < 7; i++) {
    ret[`nested-${i}`] = {
      paddingLeft: theme.spacing(3 * (i - 1) + 1),
      backgroundColor: "#202020",
      fontSize: `${1.0 * 0.95 ** i}rem`,
    }
    ret[`nested-${i}-current`] = {
      paddingLeft: theme.spacing(3 * (i - 1) + 1),
      backgroundColor: "#808080",
    }
  }
  return ret
})

const TOC = ({ headings, maxDepth }) => {
  const classes = useStyles()

  const [currentId, setCurrentId] = useState("")

  useEffect(() => {
    const onScroll = () => {
      var headingPositions = []
      for (var i = 1; i <= maxDepth; i++) {
        document.querySelectorAll(`h${i}`).forEach(node => {
          headingPositions.push([
            window.pageYOffset + node.getBoundingClientRect().top,
            node.id,
          ])
        })
      }
      headingPositions.push([Infinity, ""])
      headingPositions.sort((a, b) => a[0] - b[0])

      var tmp = ""
      for (i = 0; i < headingPositions.length; i++) {
        const [offset, id] = headingPositions[i]
        if (offset > window.pageYOffset + 1) {
          break
        }
        tmp = id
      }
      setCurrentId(tmp)
    }
    window.addEventListener("scroll", onScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", onScroll, { passive: true })
    }
  }, [maxDepth])

  const listItems = []
  for (var i = 0; i < headings.length; i++) {
    const { id, depth, value } = headings[i]
    if (depth > maxDepth) continue
    listItems.push(
      <ListItem
        className={
          currentId !== id
            ? classes[`nested-${depth}`]
            : classes[`nested-${depth}-current`]
        }
        button
        component="a"
        href={`#${id}`}
      >
        {value}
      </ListItem>
    )
  }

  return <>{listItems}</>
}

export default TOC
